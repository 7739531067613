import { css, styled } from 'styled-components';

export enum MediaContainerSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export enum MediaContainerFit {
  cover = 'cover',
  contain = 'contain',
  fill = 'fill',
  none = 'none',
}

const smallVariant = css`
  max-height: 368px;
  aspect-ratio: 1 / 1;
`;

const mediumVariant = css`
  max-height: 368px;
  aspect-ratio: 16 / 9;
`;

const largeVariant = css`
  max-height: 388px;
  aspect-ratio: 9 / 4;
`;

export const VariantRatioStyles = {
  [MediaContainerSize.small]: smallVariant,
  [MediaContainerSize.medium]: mediumVariant,
  [MediaContainerSize.large]: largeVariant,
};

export const BaseMediaContainer = styled.div`
  margin: 24px 0;
  @media ${({ theme }) => theme.breakpoints.large} {
    width: 794px; // allows image to overflow parent container
  }
  @media ${({ theme }) => theme.breakpoints.xlarge} {
    width: 886px; // allows image to overflow parent container
  }
`;

export const MediaAspectContainer = styled.div<{
  $size: MediaContainerSize;
  $outline: boolean;
  $fit?: MediaContainerFit;
}>`
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-sizing: border-box;
  ${({ $size }) => VariantRatioStyles[$size]};
  ${({ $outline, theme }) =>
    $outline && `border: solid 1px ${theme.colors.gray200};`}

  & > img, & > video {
    object-fit: ${({ $fit }) => $fit || MediaContainerFit.cover};
  }

  & > video {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0px;
  }
  @media ${({ theme }) => theme.breakpoints.large} {
    border-radius: 40px;
    margin: 24px 0 48px;
  }
`;
