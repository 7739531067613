import { styled } from 'styled-components';
import Typography from '../../Typography';

export const BlogHeaderContainer = styled.div`
  margin: 80px 0 48px;
`;

export const DateLabel = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const BlogHeaderTitle = styled(Typography)`
  margin-bottom: 8px;
`;

export const BlogHeaderSubtitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 24px;
  letter-spacing: 0;
`;
