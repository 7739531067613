import type { SVGAttributes } from 'react';

const LinkIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.00001 9.55019C3.75001 9.55019 2.67935 9.10453 1.78801 8.2132C0.896012 7.3212 0.450012 6.2502 0.450012 5.0002C0.450012 3.7502 0.896012 2.6792 1.78801 1.7872C2.67935 0.895862 3.75001 0.450195 5.00001 0.450195H8.05001C8.26668 0.450195 8.44601 0.525196 8.58801 0.675196C8.72935 0.825196 8.80001 1.0002 8.80001 1.2002C8.80001 1.41686 8.72935 1.59586 8.58801 1.7372C8.44601 1.8792 8.26668 1.9502 8.05001 1.9502H5.00001C4.16668 1.9502 3.45001 2.2502 2.85001 2.8502C2.25001 3.4502 1.95001 4.16686 1.95001 5.0002C1.95001 5.83353 2.25001 6.5502 2.85001 7.1502C3.45001 7.7502 4.16668 8.0502 5.00001 8.0502H8.05001C8.26668 8.0502 8.44601 8.12086 8.58801 8.2622C8.72935 8.4042 8.80001 8.58353 8.80001 8.8002C8.80001 9.0002 8.72935 9.1752 8.58801 9.3252C8.44601 9.4752 8.26668 9.55019 8.05001 9.55019H5.00001ZM7.00001 5.7502C6.78335 5.7502 6.60435 5.6792 6.46301 5.5372C6.32101 5.39586 6.25001 5.21686 6.25001 5.0002C6.25001 4.78353 6.32101 4.6042 6.46301 4.4622C6.60435 4.32086 6.78335 4.2502 7.00001 4.2502H13C13.2167 4.2502 13.396 4.32086 13.538 4.4622C13.6793 4.6042 13.75 4.78353 13.75 5.0002C13.75 5.21686 13.6793 5.39586 13.538 5.5372C13.396 5.6792 13.2167 5.7502 13 5.7502H7.00001ZM11.95 9.55019C11.7333 9.55019 11.5543 9.4752 11.413 9.3252C11.271 9.1752 11.2 9.0002 11.2 8.8002C11.2 8.58353 11.271 8.4042 11.413 8.2622C11.5543 8.12086 11.7333 8.0502 11.95 8.0502H15C15.8333 8.0502 16.55 7.7502 17.15 7.1502C17.75 6.5502 18.05 5.83353 18.05 5.0002C18.05 4.16686 17.75 3.4502 17.15 2.8502C16.55 2.2502 15.8333 1.9502 15 1.9502H11.95C11.7333 1.9502 11.5543 1.8792 11.413 1.7372C11.271 1.59586 11.2 1.41686 11.2 1.2002C11.2 1.0002 11.271 0.825196 11.413 0.675196C11.5543 0.525196 11.7333 0.450195 11.95 0.450195H15C16.25 0.450195 17.3207 0.895862 18.212 1.7872C19.104 2.6792 19.55 3.7502 19.55 5.0002C19.55 6.2502 19.104 7.3212 18.212 8.2132C17.3207 9.10453 16.25 9.55019 15 9.55019H11.95Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkIcon;
