import type { SVGAttributes } from 'react';

const XIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.1685 7.663L16.5291 0.269257H15.0219L9.49893 6.68914L5.08776 0.269257H0L6.67055 9.97726L0 17.7307H1.50736L7.33974 10.9511L11.9983 17.7307H17.086L10.1681 7.663H10.1685ZM8.10397 10.0628L7.4281 9.09609L2.05048 1.40397H4.36569L8.70549 7.61176L9.38136 8.57845L15.0226 16.6476H12.7074L8.10397 10.0632V10.0628Z"
      fill="currentColor"
    />
  </svg>
);

export default XIcon;
