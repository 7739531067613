import { ReactNode } from 'react';
import Container from '../Container/Container';
import Button from '../../Button';
import {
  ButtonWrapper,
  CallToActionWrapper,
  StyledHeader,
} from './CallToAction.styles';

export type CallToActionProps = {
  className?: string;
  headerText: string;
  children?: ReactNode;
  button: {
    text: string;
    url: string;
  };
};

function CallToAction(props: CallToActionProps) {
  const { headerText, children, button, className, ...otherProps } = props;

  if (!headerText) {
    return null;
  }

  return (
    <Container {...otherProps} className={className}>
      <CallToActionWrapper>
        <StyledHeader variant="heading4" tagName="h3">
          {headerText}
        </StyledHeader>
        {children}
        <ButtonWrapper>
          <Button href={button.url} external>
            {button.text}
          </Button>
        </ButtonWrapper>
      </CallToActionWrapper>
    </Container>
  );
}

export default CallToAction;
