import Image from 'next/image';
import Container from '../Container/Container';
import {
  HeroContainer,
  HeroImageWrapper,
  HeroImageDescription,
  ImageContainer,
} from './HeroImage.styles';

export type HeroImageProps = {
  image: {
    src: string;
    alt: string;
  };
  description?: string;
  className?: string;
};

function HeroImage({
  image,
  description,
  className,
  ...otherProps
}: HeroImageProps) {
  return (
    <HeroContainer className={className}>
      <HeroImageWrapper {...otherProps}>
        <ImageContainer>
          <Image src={image.src} alt={image.alt} fill />
        </ImageContainer>
        {description && (
          <Container>
            <HeroImageDescription tagName="p" color="gray700">
              {description}
            </HeroImageDescription>
          </Container>
        )}
      </HeroImageWrapper>
    </HeroContainer>
  );
}

export default HeroImage;
