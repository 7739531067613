import { styled } from 'styled-components';
import Typography from '../../Typography';

export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeroImageWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin-bottom: 45px;
`;

export const ImageContainer = styled.div`
  margin: 0 20px 16px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  aspect-ratio: 9 / 4;

  @media ${({ theme }) => theme.breakpoints.large} {
    margin: 0 53px 16px;
    border-radius: 32px;
  }

  & > img {
    object-fit: cover;
  }
`;

export const HeroImageDescription = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;
