import { styled } from 'styled-components';

export const BaseContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  // !important is needed to override builder.io passed styles
  margin: 0 auto !important;
  box-sizing: border-box;

  @media ${({ theme }) => theme.breakpoints.medium} {
    max-width: 658px;
  }

  @media (min-width: 1440px) {
    max-width: 1440px;
    padding: 0 486px 0 296px !important;
  }
`;
