import { styled } from 'styled-components';
import Typography from '../../Typography';

export const StyledHeader = styled(Typography)`
  margin: 24px 0 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const CallToActionWrapper = styled.div`
  padding: 0 0 136px;
`;
