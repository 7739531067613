import copyToClipboard from '../../../utils/copyToClipboard';
import {
  createEmailShareUrl,
  createLinkedInShareUrl,
  createTwitterShareUrl,
} from '../../../utils/shareFunctions';
import Button from '../../Button';
import { useToast } from '../../Toasts/useToast';
import Typography from '../../Typography';
import LinkIcon from '../../icons/LinkIcon';
import LinkedInIcon from './LinkedInIcon';
import MailOutlineIcon from '../../icons/MailOutlineIcon';
import { ShareActionsContainer } from './ShareActions.styles';
import XIcon from './XIcon';

type ShareActionsProps = {
  postTitle: string;
  postUrl: string;
};

function ShareActions({ postTitle, postUrl }: ShareActionsProps) {
  const { toast } = useToast();

  const shareLinkLinkedIn = createLinkedInShareUrl(postUrl);
  const shareLinkTwitter = createTwitterShareUrl(postUrl, postTitle);
  const shareEmailLink = createEmailShareUrl(postUrl, postTitle);

  const handleCopyLink = () => {
    copyToClipboard(postUrl);
    toast({
      description: `Link Copied to Clipboard`,
    });
  };

  return (
    <ShareActionsContainer>
      <Typography variant="eyebrowLarge">Share</Typography>
      <Button
        aria-label="Share to X"
        href={shareLinkTwitter}
        external
        variant="tertiary"
      >
        <XIcon />
      </Button>
      <Button
        aria-label="Share to LinkedIn"
        href={shareLinkLinkedIn}
        external
        variant="tertiary"
      >
        <LinkedInIcon />
      </Button>
      <Button
        aria-label="Share by email"
        href={shareEmailLink}
        variant="tertiary"
      >
        <MailOutlineIcon />
      </Button>
      <Button aria-label="Copy URL" onClick={handleCopyLink} variant="tertiary">
        <LinkIcon />
      </Button>
    </ShareActionsContainer>
  );
}

export default ShareActions;
