import { ReactNode } from 'react';
import { BaseContainer } from './Container.styles';

type ContainerProps = {
  children?: ReactNode;
  className?: string;
};

function Container({ children, className, ...otherProps }: ContainerProps) {
  return (
    <BaseContainer className={className} {...otherProps}>
      {children}
    </BaseContainer>
  );
}

export default Container;
