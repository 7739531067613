import { Provider, Close } from '@radix-ui/react-toast';
import { useToast } from './useToast';
import CloseIcon from '../icons/CloseIcon';
import { Viewport, Toast, Title, Description } from './Toaster.styles';

export const Toaster = () => {
  const { toasts } = useToast();

  return (
    <Provider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && <Title>{title}</Title>}
              {description && <Description>{description}</Description>}
              {action}
            </div>
            <Close toast-close="Close toast">
              <CloseIcon width={10} />
            </Close>
          </Toast>
        );
      })}
      <Viewport />
    </Provider>
  );
};
