import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { Toast, ToastContextType, ToasterToast } from './types';
import { toastReducer } from './toastReducer';
import { Toaster } from './Toaster';
import { v4 } from 'uuid';

const TOAST_REMOVE_DELAY = 5_000;

const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>();

const defaultMethod = () => {
  throw new Error('Must be used within a ToastProvider');
};

const defaultState: ToastContextType = {
  toast: defaultMethod,
  dismiss: defaultMethod,
  toasts: [],
};

export const ToastContext = createContext<ToastContextType>(defaultState);

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(toastReducer, defaultState);

  const toast = useCallback((props: Toast) => {
    const id = v4();
    const addToRemoveQueue = (toastId: string) => {
      if (toastTimeouts.has(toastId)) {
        return;
      }

      dispatch({
        type: 'DISMISS_TOAST',
        toastId,
      });

      const timeout = setTimeout(() => {
        toastTimeouts.delete(toastId);
        dispatch({
          type: 'REMOVE_TOAST',
          toastId: toastId,
        });
      }, TOAST_REMOVE_DELAY);

      toastTimeouts.set(toastId, timeout);
    };

    const update = (props: ToasterToast) =>
      dispatch({
        type: 'UPDATE_TOAST',
        toast: { ...props, id },
      });
    const dismiss = () => addToRemoveQueue(id);

    dispatch({
      type: 'ADD_TOAST',
      toast: {
        ...props,
        id,
        open: true,
        onOpenChange: (open) => {
          if (!open) dismiss();
        },
      },
    });

    return {
      id: id,
      dismiss,
      update,
    };
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      toast,
      dismiss: (toastId?: string) =>
        dispatch({ type: 'DISMISS_TOAST', toastId }),
    }),
    [state, toast],
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Toaster />
    </ToastContext.Provider>
  );
};
