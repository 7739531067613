import { ReactNode } from 'react';
import {
  BaseMediaContainer,
  MediaAspectContainer,
  MediaContainerFit,
  MediaContainerSize,
} from './MediaContainer.styles';

export type MediaContainerProps = {
  children?: ReactNode;
  className?: string;
  size: MediaContainerSize;
  outline?: boolean;
  fit?: MediaContainerFit;
};

function MediaContainer({
  children,
  className,
  size,
  outline,
  fit,
  ...otherProps
}: MediaContainerProps) {
  return (
    <BaseMediaContainer className={className} {...otherProps}>
      <MediaAspectContainer $size={size} $outline={!!outline} $fit={fit}>
        {children}
      </MediaAspectContainer>
    </BaseMediaContainer>
  );
}

export default MediaContainer;
