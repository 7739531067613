import styled, { keyframes } from 'styled-components';
import {
  Root,
  ToastDescription,
  Viewport as ToastPrimitives_Viewport,
  ToastTitle,
} from '@radix-ui/react-toast';
import { BodySmall, EyebrowLarge } from '../Typography/Typography.styles';

const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
`;
const swipeOut = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
`;

export const Toast = styled(Root)`
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  padding: 16px;
  display: grid;
  grid-template-areas: 'title action' 'description action';
  grid-template-columns: auto max-content;
  column-gap: 44px;
  align-items: center;
  max-width: 375px;

  &[data-state='open'] {
    animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='closed'] {
    animation: ${hide} 100ms ease-in;
  }

  &[data-swipe='move'] {
    transform: translateX(-100px);
  }

  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe='end'] {
    animation: ${swipeOut} 100ms ease-out;
  }

  & button {
    all: unset;
  }
`;

export const Viewport = styled(ToastPrimitives_Viewport)`
  --viewport-padding: 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
`;

export const Title = styled(ToastTitle)`
  grid-area: title;
  margin-bottom: 5px;
  ${EyebrowLarge};
`;

export const Description = styled(ToastDescription)`
  grid-area: description;
  margin: 0;
  line-height: 1.3;
  ${BodySmall};
`;
