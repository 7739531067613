/* eslint-disable @next/next/no-page-custom-font */
import Head from 'next/head';

function Seo({ pageData }: { pageData?: Record<string, string> }) {
  const resolvedUrl = `https://wreno.io${pageData?.url || ''}`;

  return (
    <Head>
      <title key="title">{pageData?.title}</title>
      <meta name="description" content={pageData?.description} />
      <meta
        property="og:title"
        content={pageData?.ogTitle || pageData?.title}
      />
      <meta
        property="og:description"
        content={pageData?.ogDescription || pageData?.description}
      />
      <meta property="og:url" content={resolvedUrl} />
      <link rel="canonical" href={resolvedUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Wreno" />
      <meta property="og:image" content={pageData?.ogImage} />
      <meta
        property="og:image:alt"
        content={pageData?.ogImageAlt || pageData?.ogTitle || pageData?.title}
      />
    </Head>
  );
}

export default Seo;
