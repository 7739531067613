import {
  BlogHeaderContainer,
  BlogHeaderSubtitle,
  BlogHeaderTitle,
  DateLabel,
} from './BlogHeader.styles';
import Container from '../Container/Container';
import ShareActions from '../ShareActions/ShareActions';
import { ToastProvider } from '../../Toasts/ToastContext';

export type BlogHeaderProps = {
  title: string;
  subtitle?: string;
  date: Date;
  postUrl: string;
  className?: string;
};

function BlogHeader({
  title,
  subtitle,
  date,
  postUrl,
  className,
  ...otherProps
}: BlogHeaderProps) {
  const formattedDate = new Date(date).toLocaleDateString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <Container className={className} {...otherProps}>
      <BlogHeaderContainer>
        <DateLabel tagName="p" variant="bodyStandard" color="machineGreen600">
          {formattedDate}
        </DateLabel>
        <BlogHeaderTitle
          tagName="h1"
          variant="heading2"
          color="machineGreen600"
        >
          {title}
        </BlogHeaderTitle>
        {subtitle && (
          <BlogHeaderSubtitle tagName="h2" variant="heading4" color="gray900">
            {subtitle}
          </BlogHeaderSubtitle>
        )}
        <ToastProvider>
          <ShareActions postTitle={title} postUrl={postUrl} />
        </ToastProvider>
      </BlogHeaderContainer>
    </Container>
  );
}

export default BlogHeader;
