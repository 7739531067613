import type { SVGAttributes } from 'react';

const LinkedInIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2090_21190)">
      <path
        d="M15.8298 0.5H1.34205C0.64316 0.5 0.0859375 1.05722 0.0859375 1.75611V16.2439C0.0859375 16.9333 0.64316 17.5 1.34205 17.5H15.8298C16.5193 17.5 17.0859 16.9428 17.0859 16.2439V1.75611C17.0859 1.06667 16.5287 0.5 15.8298 0.5ZM5.14816 14.9783H2.58872V6.86556H5.14816V14.9878V14.9783ZM3.87316 5.73222C3.06094 5.73222 2.39983 5.10889 2.39983 4.26833C2.39983 3.42778 3.06094 2.80444 3.87316 2.80444C4.68538 2.80444 5.34649 3.42778 5.34649 4.26833C5.34649 5.10889 4.68538 5.73222 3.87316 5.73222ZM14.5737 14.9783H12.0143V10.5394C12.0143 9.22667 11.457 8.83 10.7393 8.83C9.98372 8.83 9.2376 9.39667 9.2376 10.5772V14.9783H6.67816V6.86556H9.13371V7.98944H9.16205C9.4076 7.48889 10.2765 6.63889 11.5893 6.63889C13.0154 6.63889 14.5548 7.48889 14.5548 9.96333V14.9878L14.5737 14.9783Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2090_21190">
        <rect
          width="17"
          height="17"
          fill="currentColor"
          transform="translate(0.0859375 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LinkedInIcon;
