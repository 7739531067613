export const createLinkedInShareUrl = (url: string) =>
  `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;

export const createTwitterShareUrl = (postUrl: string, postTitle: string) => {
  const shareText = encodeURIComponent(
    `Check out this blog post: ${postTitle}`,
  );
  const shareUrl = encodeURIComponent(postUrl);

  return `https://twitter.com/intent/tweet?text=${shareText}&url=${shareUrl}`;
};

export const createEmailShareUrl = (url: string, subject: string) => {
  const emailBody = `Check out this blog post from Wreno:\n\n${url}`;
  return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
};
